import {IControllerFactoryConfig} from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import {I$W, IWidgetController} from '@wix/native-components-infra/dist/es/src/types/types';
import {CartIconStore} from './CartIconStore';
import {ICartIconControllerConfigEditor} from '../types/app-types';
import {withErrorReporting} from '@wix/wixstores-client-core/dist/es/src/viewer-script/errorReporter';
import {SiteStore} from '@wix/wixstores-client-core/dist/es/src/viewer-script/site-store/siteStore';
import {IAddToCartItem} from '../types/cart';
import {IAddToCartOptions} from '../types/product';

export function cartWidgetController(controllerFactoryConfig: IControllerFactoryConfig): IWidgetController {
  let cartIconStore: CartIconStore;
  let siteStore: SiteStore;

  const {config, setProps, context: factoryContext, reportError} = controllerFactoryConfig;

  return {
    pageReady: () => {
      siteStore = factoryContext.siteStore;
      cartIconStore = new CartIconStore(siteStore, config, setProps, reportError);
      return cartIconStore.setInitialState();
    },
    updateConfig: (_$w: I$W, updatedConfig: ICartIconControllerConfigEditor) => {
      config.style = updatedConfig.style;
      config.publicData = updatedConfig.publicData;
      setProps({
        displayText: cartIconStore.getDisplayText(updatedConfig.publicData ? updatedConfig.publicData.appSettings : {}),
      });
    },
    onBeforeUnLoad: () => {
      cartIconStore.unSubscribeAll();
    },
    exports: () =>
      withErrorReporting(controllerFactoryConfig.reportError)({
        addToCart(
          productId: string,
          quantity: number = 1,
          options: IAddToCartOptions = {}
        ): Promise<boolean> | Promise<boolean[]> {
          return cartIconStore.addToCart(`${productId}`, quantity, options);
        },
        addProductsToCart(cartItems: IAddToCartItem[]): Promise<boolean> | Promise<boolean[]> {
          return cartIconStore.addItemsToCart(cartItems);
        },
      }),
  };
}
